import * as actionTypes from "../actions/AuthActionTypes";

const State = {
  authenticated: false,
  token: null,
  tokenExpire: null,
  patients: null,
  loggedInUserID: null,
  error: null,
  requiresUpdate: null,
};

const reducer = (state = State, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        authenticated: action.auth,
        token: action.token,
        tokenExpire: action.exp,
        patients: action.patients,
        loggedInUserID: action.LoginUser,
        requiresUpdate: action.requiresUpdate,
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        authenticated: false,
        token: null,
        patients: null,
        loggedInUserID: null,
        requiresUpdate: null,
      };

    case actionTypes.ERROR:
      return { ...state, error: action.error };

    case actionTypes.UPDATE_DETAILS:
      return {
        ...state,
        patients: action.patients,
        requiresUpdate: action.requiresUpdate,
      };

    default:
      return state;
  }
};

export default reducer;
