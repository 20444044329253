import * as actionTypes from "../actions/FPActionTypes";

const State = {
  searchCriteria: null,
  selectedDoctor: null,
  action: null,
  doctorSearchResults: null,
  centers: null,
  towns: null,
  disciplines: null,
  provinces: null,
  covidAnswers: null,
  bookingSelection: null,
};

const reducer = (state = State, action) => {
  switch (action.type) {
    case actionTypes.SEARCH:
      return {
        ...state,
        searchCriteria: action.criteria,
      };

    case actionTypes.SAVE_ACTION:
      return {
        ...state,
        action: action.actionType,
      };

    case actionTypes.UPDATE_ACTION:
      let currentAction = { ...state.action, current: action.actionType };
      return {
        ...state,
        action: currentAction,
      };

    case actionTypes.SELECT_DOCTOR:
      return {
        ...state,
        selectedDoctor: action.selectedDoctor,
      };

    case actionTypes.SAVE_SEARCH_RESULTS:
      return {
        ...state,
        doctorSearchResults: action.results,
      };

    case actionTypes.SAVE_TOWNS:
      return {
        ...state,
        towns: action.towns,
      };

    case actionTypes.SAVE_PROVINCES:
      return {
        ...state,
        provinces: action.provinces,
      };

    case actionTypes.SAVE_CENTERS:
      return {
        ...state,
        centers: action.centers,
      };

    case actionTypes.SAVE_DISCIPLINES:
      return {
        ...state,
        disciplines: action.disciplines,
      };

    case actionTypes.SAVE_COVID_RESPONSE:
      return {
        ...state,
        covidAnswers: action.response,
      };

    case actionTypes.SELECT_BOOKING_OPTION:
      return {
        ...state,
        bookingSelection: action.booking,
      };

    case actionTypes.CLEAR_LOCAL_FP:
      return {
        ...state,
        selectedDoctor: null,
        action: null,
        doctorSearchResults: null,
        centers: null,
        towns: null,
        disciplines: null,
        provinces: null,
      };

    default:
      return state;
  }
};

export default reducer;
