import React from "react";

import classes from "./Spinner.module.css";
import Logo from "../../UI/Logo/Logo";

const spinner = (props) => {
  return (
    <React.Fragment>
      <div className={classes.Spinner}>
        <Logo class={classes.Logo} />
      </div>
      <p className={classes.Searching}>Loading</p>
    </React.Fragment>
  );
};

export default React.memo(spinner);
