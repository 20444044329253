import React from "react";

import classes from "./Backdrop.module.css";
import PropType from "prop-types";

const backdrop = props => {
  return props.show ? (
    <div
      onClick={props.clicked}
      className={classes.Backdrop}
      style={props.style}
    />
  ) : null;
};

backdrop.propTypes = {
  clicked: PropType.func
};

export default backdrop;
