export const SEARCH = "SEARCH";
export const AUTO_POPULATE_SEARCH = "AUTO_POPULATE_SEARCH";
export const SAVE_ACTION = "SAVE_ACTION";
export const UPDATE_ACTION = "UPDATE_ACTION";
export const SELECT_DOCTOR = "SELECT_DOCTOR";
export const SAVE_SEARCH_RESULTS = "SAVE_SEARCH_RESULTS";
export const SAVE_PROVINCES = "SAVE_PROVINCES";
export const SAVE_CENTERS = "SAVE_CENTERS";
export const SAVE_DISCIPLINES = "SAVE_DISCIPLINES";
export const SAVE_TOWNS = "SAVE_TOWNS";
export const SAVE_COVID_RESPONSE = "SAVE_COVID_RESPONSE";
export const SELECT_BOOKING_OPTION = "SELECT_BOOKING_OPTION";
export const CLEAR_LOCAL_FP = "CLEAR_LOCAL_FP";
