import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./NavigationItem.module.css";
import PropType from "prop-types";

const navigationItem = (props) => {
  return (
    <li className={props.nav ? classes.NavigationItem : classes.SideBarItem}>
      {props.internal ? (
        <NavLink
          activeClassName={classes.active}
          to={props.link}
          onClick={props.clicked}
        >
          {props.children}
        </NavLink>
      ) : (
        <a href={props.link} target="_self">
          {props.children}
        </a>
      )}
    </li>
  );
};

navigationItem.propTypes = {
  active: PropType.bool,
  link: PropType.string.isRequired,
  nav: PropType.bool.isRequired,
  internal: PropType.bool.isRequired,
};

export default navigationItem;
