import React from "react";

import classes from "./HamburgerToggle.module.css";
import PropType from "prop-types";

const hamburgerToggle = (props) => {
  return (
    <div className={classes.DrawerToggle} onClick={props.clicked}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

hamburgerToggle.propTypes = {
  clicked: PropType.func,
};

export default hamburgerToggle;
