import React, { Component } from "react";

import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as PatientPortalActions from "../store/actions/index";

import axios from "../axios";
import withError from "../hoc/withErrorHandler/withErrorHandler";
import LazyLoader from "../hoc/LazyLoader/LazyLoader";

const Home = LazyLoader(() => import("../components/Home/Home"));
const Gallery = LazyLoader(() => import("../components/Gallery/Gallery"));
const Contact = LazyLoader(() => import("../components/Contact/Contact"));

class PatientPortal extends Component {
  componentDidMount() {
    this.props.onAutoLogin();
    this.props.onAutoFP();
  }

  loginHandler = (data) => {
    if (data.success) {
      this.props.onLogin(
        data.success,
        data.token,
        data.expiresIn,
        data.patients.Patients,
        { id: data.patients.id, Name: data.patients.Name },
        data.needsUpdate
      );
    }
  };

  render() {
    return (
      <Switch>
        <Route path="/Contact-Us" component={Contact} />
        <Route path="/Gallery" component={Gallery} />
        <Route path="/Home" component={Home} />
        <Redirect to="/Home" />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    patients: state.auth.patients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (auth, token, exp, patients, loginID, requiresUpdate) =>
      dispatch(
        PatientPortalActions.loginActionHandler(
          auth,
          token,
          exp,
          patients,
          loginID,
          requiresUpdate
        )
      ),
    onAutoLogin: () => dispatch(PatientPortalActions.checkTokenActionHandler()),
    onAutoFP: () =>
      dispatch(PatientPortalActions.autoLoadLocalFPActionHandler()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withError(PatientPortal, axios)));
