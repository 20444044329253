import React from "react";

import classes from "./Toolbar.module.css";
import Logo from "../../UI/Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import HamburgerToggle from "../SideDrawer/HamburgerToggle/HamburgerToggle";
import PropType from "prop-types";

const toolbar = (props) => {
  return (
    <header className={classes.Toolbar}>
      {/* <div className={classes.Announcement}>Hello</div> */}
      <HamburgerToggle clicked={props.hamburgerToggleClicked}>
        Menu
      </HamburgerToggle>
      <div className={classes.LogoHolder}>
        <Logo class={classes.Logo} />
      </div>
      <nav className={classes.DesktopOnly}>
        <NavigationItems
          items={[
            {
              value: "Home",
              link: "/Home",
              internal: true,
            },
            {
              value: "Gallery",
              link: "/Gallery",
              internal: true,
            },
            {
              value: "Contact Us",
              link: "Contact-Us",
              internal: true,
            },
          ]}
          nav={true}
          isAuthenticated={false}
        />
      </nav>
    </header>
  );
};

toolbar.propTypes = {
  hamburgerToggleClicked: PropType.func.isRequired,
};

export default toolbar;
