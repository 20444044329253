import * as actionTypes from "./AuthActionTypes";

export const loginActionHandler = (
  auth,
  token,
  exp,
  patients,
  loggedInUser,
  requiresUpdate
) => {
  localStorage.setItem(
    "NHCPatients",
    JSON.stringify({
      token: token,
      expire: new Date(exp),
      patients: patients,
      LoginUser: loggedInUser,
    })
  );
  return {
    type: actionTypes.LOGIN,
    auth: auth,
    token: token,
    exp: exp,
    patients: patients,
    LoginUser: loggedInUser,
    requiresUpdate: requiresUpdate,
  };
};

export const logoutActionHandler = () => {
  localStorage.removeItem("NHCPatients");
  return {
    type: actionTypes.LOGOUT,
    auth: false,
    token: null,
    exp: null,
    patients: null,
    patientsID: null,
    LoginUser: null,
    error: null,
    requiresUpdate: null,
  };
};

export const detailsUpdateActionHandler = (patients, requiresUpdate) => {
  const token = JSON.parse(localStorage.getItem("NHCPatients"));
  if (token) {
    token.patients = patients;
    localStorage.setItem(
      "NHCPatients",
      JSON.stringify({
        ...token,
      })
    );
  }
  return {
    type: actionTypes.UPDATE_DETAILS,
    patients: patients,
    requiresUpdate: requiresUpdate,
  };
};

export const checkTokenActionHandler = () => {
  return (dispatch) => {
    const token = JSON.parse(localStorage.getItem("NHCPatients"));
    if (!token) {
      dispatch(logoutActionHandler());
    } else {
      const expDate = new Date(token.expire);
      if (expDate < new Date()) {
        dispatch(logoutActionHandler());
      } else {
        dispatch(
          loginActionHandler(
            true,
            token.token,
            expDate,
            token.patients,
            token.LoginUser
          )
        );
      }
    }
  };
};

export const errorActionHandler = (error) => {
  return { type: actionTypes.ERROR, error: error };
};
