import React from "react";

import classes from "./SideDrawer.module.css";
import Logo from "../../UI/Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import Backdrop from "../../UI/Backdrop/Backdrop";
import PropType from "prop-types";

const sideDrawer = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Closed];
  if (props.open) attachedClasses = [classes.SideDrawer, classes.Open];
  return (
    <React.Fragment>
      <Backdrop show={props.open} clicked={props.closed} />
      <div className={attachedClasses.join(" ")}>
        <div className={classes.LogoHolder}>
          <Logo class={classes.Logo} />
        </div>
        <nav>
          <NavigationItems
            items={[
              {
                value: "Home",
                link: "/Home",
                internal: true,
              },
              {
                value: "Gallery",
                link: "/Gallery",
                internal: true,
              },
              {
                value: "Contact Us",
                link: "Contact-Us",
                internal: true,
              },
            ]}
            nav={true}
            clicked={props.closed}
          />
        </nav>
      </div>
    </React.Fragment>
  );
};

sideDrawer.propTypes = {
  open: PropType.bool.isRequired,
  closed: PropType.func,
};

export default sideDrawer;
