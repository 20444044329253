import React, { Component, Suspense } from "react";

import Spinner from "../../components/UI/Spinner/Spinner";

const LazyLoader = (importComponent) => {
  return class extends Component {
    state = {
      component: null,
    };

    componentDidMount() {
      importComponent().then((cmp) => {
        this.setState({ component: cmp.default });
      });
    }

    render() {
      const C = this.state.component;

      return C ? (
        <Suspense
          fallback={
            <div
              style={{
                textAlign: "center",
                width: "100%",
                height: "fit-content",
                alignSelf: "center",
              }}
            >
              <Spinner />
            </div>
          }
        >
          <C {...this.props} />
        </Suspense>
      ) : (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            height: "fit-content",
            alignSelf: "center",
          }}
        >
          <Spinner />
        </div>
      );
    }
  };
};

export default LazyLoader;
