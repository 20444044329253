import axios from "axios";

const instance = axios.create({
	// baseURL: "http://localhost:8080",
	baseURL: "https://apron-strings.co.za",
	timeout: 1000 * 45, // 45 seconds
});

//https://css-tricks.com/stay-dry-using-axios-for-api-requests/

export default instance;
