import React from "react";

import classes from "./Button.module.css";
import PropType from "prop-types";

const button = (props) => {
  return (
    <button
      className={[
        classes.Button,
        classes[props.buttonType],
        props.customClasses,
      ].join(" ")}
      disabled={props.disabled}
      type={props.type ? props.type.toLowerCase() : "button"}
      onClick={props.clicked}
    >
      {props.children}
    </button>
  );
};

button.propTypes = {
  buttonType: PropType.string.isRequired,
  clicked: PropType.func,
  customClasses: PropType.string,
  type: PropType.string,
  disabled: PropType.bool,
};

export default button;
