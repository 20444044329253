import React, { Component } from "react";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import AuthReducer from "./store/reducers/AuthReducer";
import FPReducer from "./store/reducers/FPReducer";
import ApronStrings from "./container/ApronStrings";
import Layout from "./hoc/Layout/Layout";

import packageJson from "../package.json";
global.appVersion = packageJson.version;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  auth: AuthReducer,
  FP: FPReducer,
});

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Layout>
          <ApronStrings />
        </Layout>
      </Provider>
    );
  }
}

export default App;
