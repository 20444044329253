import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as DoctorPortalActions from "../../store/actions/index";

import errorImage from "../../assets/sad404.svg";
import Button from "../../components/UI/Button/Button";

const withErrorHandler = (WrappedComponent, axios) => {
  return class extends Component {
    constructor(props) {
      super(props);
      // Response can possible set an error
      this.resInterceptor = axios.interceptors.response.use(
        (res) => res,
        (error) => {
          this.props.onError(error.response.data);
          return error.response;
        }
      );

      // new request clears any errors
      this.reqInterceptor = axios.interceptors.request.use((req) => {
        this.props.onError(null);
        return req;
      });
    }

    componentWillUnmount() {
      axios.interceptors.response.eject(this.resInterceptor);
      axios.interceptors.request.eject(this.reqInterceptor);
    }

    render() {
      let error = <WrappedComponent {...this.props} />;
      if (this.props.error) {
        error = (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              height: "fit-content",
            }}
          >
            <img
              src={errorImage}
              alt="An error has occurred"
              style={
                window.screen.width > 991
                  ? { height: "200px", width: "200px" }
                  : { height: "100px", width: "100px" }
              }
            />
            <h3>The Following Error Occurred</h3>
            <h3 className="redText">{this.props.error.message}</h3>
            <Button
              buttonType="Cancel"
              clicked={() => {
                setTimeout(() => {
                  this.props.onError(null);
                }, 500);
                this.props.history.go(-1);
              }}
            >
              Back
            </Button>
            <Button
              buttonType="Success"
              clicked={() => {
                this.props.onError(null);
              }}
            >
              Retry
            </Button>
          </div>
        );
      }
      return error;
    }
  };
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onError: (error) => dispatch(DoctorPortalActions.errorActionHandler(error)),
  };
};
const wrappedWithError = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler
);

export default wrappedWithError;
