import React from "react";

import classes from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import PropType from "prop-types";
// import Install from "../../Installation/Installation";

const navigationItems = (props) => {
  return (
    <React.Fragment>
      <ul
        className={props.nav ? classes.NavigationItems : classes.SideBarItems}
      >
        {props.items.length > 0 &&
          props.items.map((e, i) => {
            return (
              <NavigationItem
                key={i}
                link={
                  e.link[0] === "/"
                    ? e.link
                    : e.link.substr(0, 4) !== "http"
                    ? "/" + e.link
                    : e.link
                }
                nav={props.nav}
                internal={e.internal}
                clicked={props.clicked}
              >
                {e.value}
              </NavigationItem>
            );
          })}
      </ul>
    </React.Fragment>
  );
};

navigationItems.propTypes = {
  items: PropType.array.isRequired,
  nav: PropType.bool.isRequired,
};

export default navigationItems;
