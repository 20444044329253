import * as actionTypes from "./FPActionTypes";

const updateLocalStorage = (key, expiry, accessor) => {
  let localStore = localStorage.getItem("NHC_FP");
  if (localStore) {
    // There is a NHC_FP key in localStorage
    localStore = JSON.parse(localStore);
    const keyIndex = localStore.findIndex((e) => JSON.parse(e).name === key);
    if (keyIndex !== -1) {
      // There is a key in NHC_FP that matches key provided
      localStore[keyIndex] = JSON.stringify({
        name: key,
        ...accessor,
        expire: expiry,
      });
    } else {
      localStore.push(
        JSON.stringify({
          name: key,
          ...accessor,
          expire: expiry,
        })
      );
    }
  } else {
    localStore = [];
    localStore.push(
      JSON.stringify({
        name: key,
        ...accessor,
        expire: expiry,
      })
    );
  }

  localStorage.setItem("NHC_FP", JSON.stringify(localStore));
};

export const searchActionHandler = (criteria) => {
  return {
    type: actionTypes.SEARCH,
    searchCriteria: criteria,
  };
};

export const autoPopulateSearchActionHandler = () => {
  return {
    type: actionTypes.AUTO_POPULATE_SEARCH,
  };
};

export const actionTypeActionHandler = (type) => {
  // Sets the action type initially
  const date = new Date();
  // Allows 10 minutes for action before expiry
  date.setHours(date.getHours(), date.getMinutes() + 10, date.getSeconds());
  updateLocalStorage("ACTION", date, { actionType: type });
  return {
    type: actionTypes.SAVE_ACTION,
    actionType: type,
  };
};

export const updateActionTypeActionHandler = (type) => {
  let currentAction;
  let localStore = localStorage.getItem("NHC_FP");
  if (localStore) {
    // There is a NHC_FP key in localStorage
    localStore = JSON.parse(localStore);
    const keyIndex = localStore.findIndex(
      (e) => JSON.parse(e).name === "ACTION"
    );
    currentAction = {
      ...JSON.parse(localStore[keyIndex]).actionType,
      current: type,
    };
  }
  const date = new Date();
  // Allows 10 minutes for action before expiry
  date.setHours(date.getHours(), date.getMinutes() + 10, date.getSeconds());
  updateLocalStorage("ACTION", date, { actionType: currentAction });
  return {
    type: actionTypes.UPDATE_ACTION,
    actionType: type,
  };
};

export const saveDoctorActionHandler = (doctorObj) => {
  const date = new Date();
  // Allows 10 minutes for doctor accessed before expiry
  date.setHours(date.getHours(), date.getMinutes() + 10, date.getSeconds());
  updateLocalStorage("DOCTORACCESSED", date, { selectedDoctor: doctorObj });
  return {
    type: actionTypes.SELECT_DOCTOR,
    selectedDoctor: doctorObj,
  };
};

export const saveSearchResultsActionHandler = (searchResults) => {
  const date = new Date();
  // Allows 2 hours for doctor searched before expiry
  date.setHours(date.getHours() + 2, date.getMinutes(), date.getSeconds());
  updateLocalStorage("SEARCHRESULTS", date, { results: searchResults });
  return {
    type: actionTypes.SAVE_SEARCH_RESULTS,
    results: searchResults,
  };
};

export const saveProvincesActionHandler = (provinces) => {
  const date = new Date();
  // Allows 1 month for provinces searched before expiry
  date.setFullYear(date.getFullYear(), date.getMonth() + 1, date.getDate());
  updateLocalStorage("PROVINCES", date, { provinces: provinces });
  return {
    type: actionTypes.SAVE_PROVINCES,
    provinces: provinces,
  };
};

export const saveCentersActionHandler = (centers) => {
  const date = new Date();
  // Allows 2 weeks for centers searched before expiry
  date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 14);
  updateLocalStorage("CENTERS", date, { centers: centers });
  return {
    type: actionTypes.SAVE_CENTERS,
    centers: centers,
  };
};

export const saveDisciplinesActionHandler = (disciplines) => {
  const date = new Date();
  // Allows 2 weeks for disciplines searched before expiry
  date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 14);
  updateLocalStorage("DISCIPLINES", date, { disciplines: disciplines });
  return {
    type: actionTypes.SAVE_DISCIPLINES,
    disciplines: disciplines,
  };
};

export const saveTownsActionHandler = (towns) => {
  const date = new Date();
  // Allows 2 weeks for towns searched before expiry
  date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 14);
  updateLocalStorage("TOWNS", date, { towns: towns });
  return {
    type: actionTypes.SAVE_TOWNS,
    towns: towns,
  };
};

export const saveCovidResponseActionHandler = (response) => {
  const date = new Date();
  // Allows 10 minutes for doctor accessed before expiry
  date.setHours(date.getHours(), date.getMinutes() + 10, date.getSeconds());
  updateLocalStorage("COVID", date, { response: response });
  return {
    type: actionTypes.SAVE_COVID_RESPONSE,
    response: response,
  };
};

export const saveSelectedBookingSlotActionHandler = (booking) => {
  return {
    type: actionTypes.SELECT_BOOKING_OPTION,
    booking: booking,
  };
};

export const autoLoadLocalFPActionHandler = () => {
  return (dispatch) => {
    let token = JSON.parse(localStorage.getItem("NHC_FP"));
    if (token) {
      token.forEach((e, i) => {
        e = JSON.parse(e);
        switch (e.name) {
          case "ACTION":
            if (
              new Date(e.expire).getTime() > new Date().getTime() &&
              e.actionType !== null
            )
              dispatch(actionTypeActionHandler(e.actionType));
            else {
              dispatch(actionTypeActionHandler(null));
              token[i] = null;
            }
            break;

          case "DOCTORACCESSED":
            if (
              new Date(e.expire).getTime() > new Date().getTime() &&
              e.selectedDoctor !== null
            )
              dispatch(saveDoctorActionHandler(e.selectedDoctor));
            else {
              dispatch(saveDoctorActionHandler(null));
              token[i] = null;
            }
            break;

          case "SEARCHRESULTS":
            if (
              new Date(e.expire).getTime() > new Date().getTime() &&
              e.results !== null
            )
              dispatch(saveSearchResultsActionHandler(e.results));
            else {
              dispatch(saveSearchResultsActionHandler(null));
              token[i] = null;
            }
            break;

          case "PROVINCES":
            if (
              new Date(e.expire).getTime() > new Date().getTime() &&
              e.provinces !== null
            )
              dispatch(saveProvincesActionHandler(e.provinces));
            else {
              dispatch(saveProvincesActionHandler(null));
              token[i] = null;
            }
            break;

          case "CENTERS":
            if (
              new Date(e.expire).getTime() > new Date().getTime() &&
              e.centers !== null
            )
              dispatch(saveCentersActionHandler(e.centers));
            else {
              dispatch(saveCentersActionHandler(null));
              token[i] = null;
            }
            break;

          case "DISCIPLINES":
            if (
              new Date(e.expire).getTime() > new Date().getTime() &&
              e.disciplines !== null
            )
              dispatch(saveDisciplinesActionHandler(e.disciplines));
            else {
              dispatch(saveDisciplinesActionHandler(null));
              token[i] = null;
            }
            break;

          case "TOWNS":
            if (
              new Date(e.expire).getTime() < new Date().getTime() &&
              e.towns !== null
            ) {
              dispatch(saveTownsActionHandler(e.towns));
            } else {
              dispatch(saveTownsActionHandler(null));
              token[i] = null;
            }
            break;

          case "COVID":
            if (
              new Date(e.expire).getTime() < new Date().getTime() &&
              e.response !== null
            )
              dispatch(saveTownsActionHandler(e.response));
            else {
              dispatch(saveTownsActionHandler(null));
              token[i] = null;
            }
            break;

          case "TIMES":
            if (
              new Date(e.expire).getTime() < new Date().getTime() &&
              e.times !== null
            )
              dispatch(saveTownsActionHandler(e.times));
            else {
              dispatch(saveTownsActionHandler(null));
              token[i] = null;
            }
            break;

          default:
            break;
        }
      });
      token = token.filter((e) => e !== null);
      if (token.length > 0) {
        localStorage.setItem("NHC_FP", JSON.stringify(token));
      } else {
        localStorage.removeItem("NHC_FP");
      }
    }
  };
};
